<template>
  <div>
    <CRow>
      <FlashMessage :position="'right top'" style="z-index: 2000;"></FlashMessage>
      <CCard class="col-xl-12 padding-0">
        <CCardHeader>
            <CCol xl="6">
                <CButton color="primary" class="btn-block col-xl-2" to="/users"><v-icon name="angle-left" class="v-mid-back"/> Back</CButton>
            </CCol>
        </CCardHeader>
        <CCardBody>
            <CCol xl="12" style="text-align: center;">
              <CSpinner
                v-if="isLoading"
                color="info"
                style="width:3rem;height:3rem;"
              />
            </CCol>
            <CForm v-if="!isLoading">
              <CInput
                label="NRP"
                :description="errors.nrp"
                placeholder="Please fill NRP"
                type="text"
                vertical
                v-model="form.nrp"
              />
              <CInput
                label="Full Name"
                :description="errors.name"
                placeholder="Please fill full name"
                type="text"
                vertical
                v-model="form.name"
              />
              <CInput
                label="Email"
                :description="errors.email"
                placeholder="Please fill email"
                type="email"
                vertical
                v-model="form.email"
              />
              <CInput
                label="Password"
                :description="errors.password"
                placeholder="Please fill password"
                :type="passcfg.typepass"
                vertical
                v-model="form.password"
              >
              <template #append-content>
                <v-icon :name="passcfg.iconpass" class="v-mid" v-on:click="showPassword" style="cursor: pointer;"/>
              </template>
              </CInput>
              <CInput
                label="Phone Number"
                :description="errors.phone"
                placeholder="Please fill phone number"
                type="text"
                vertical
                v-model="form.phone"
              />
              <CTextarea
                label="Address"
                :description="errors.address"
                placeholder="Please fill address"
                vertical
                rows="4"
                v-model="form.address"
              />
              <div class="form-group" role="group">
                <label>Privilege</label>
                <v-select 
                    :reduce="roles => roles.value"
                    v-model="form.role_id"
                    :options="roles"
                    placeholder="Please select privilege"
                />
                <small class="form-text text-muted w-100">{{errors.role_id}}</small>
              </div>
              <div class="form-group" role="group" v-if="this.form.role_id!=1">
                <label>Division</label>
                <v-select 
                    :reduce="divisions => divisions.value"
                    v-model="form.division_id"
                    :options="divisions"
                    placeholder="Please select division"
                />
                <small class="form-text text-muted w-100">{{errors.division_id}}</small>
              </div>
              <div class="form-group" role="group">
                <label>Air Base</label>
                <multiselect
                  @input="changeAirport"
                  v-model="value_airports"
                  :options="airports"
                  track-by="value"
                  label="label"
                  :multiple="true"
                  placeholder="Choose Air Base"
                  :close-on-select="false"
                  :clear-on-select="false">
                </multiselect>
                <small class="form-text text-muted w-100">{{errors.airport_id}}</small>
              </div>
              <div class="form-group" role="group" v-if="$route.params.id && form.id!=1">
                <label>Activate</label>
                <v-select 
                    :reduce="activates => activates.value"
                    v-model="form.is_active"
                    :options="activates"
                    placeholder="Please select activate"
                />
                <small class="form-text text-muted w-100">{{errors.is_active}}</small>
              </div>
              <CRow>
                <CCol xl="12"><label>Profile Picture</label></CCol>
                <CCol xl="1">
                  <CButton color="danger" class="btn-sm" v-on:click="onFileRemoved"><v-icon name="times" class="v-mid"/></CButton>
                </CCol>
                <CCol xl="11">
                  <input ref="inputImage" type="file" @change="onFileChanged" />
                </CCol>
                <CCol xl="12"><span>{{errors.profile_picture}}</span></CCol>
              </CRow>
              <div id="preview">
                <img v-if="url" :src="url" />
              </div>
                <CButton color="info" class="btn-block col-xl-1 float-right padding-5 mt-2" v-on:click="$route.params.id ? updateUser():postUser()"><v-icon :name="$route.params.id ? 'pencil-alt':'plus'" class="v-mid"/> {{$route.params.id ? 'Update':'Save'}}</CButton>
            </CForm>
        </CCardBody>
      </CCard>
    </CRow>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'FormUsers',
  components: {
  },
  props: {
    valueairports: {
      type: Array,
      default: () => []
    },
  },
  data () {
    return {
      isLoading: false,
      roles: [],
      divisions: [],
      airports: [],
      activates: [
        { value: 1, label: 'Active' },
        { value: 0, label: 'Disactive' }
      ],
      value_airports: this.valueairports,
      form: {
        uuid: '',
        nrp: '',
        name: '',
        profile_picture: null,
        address: '',
        phone: '',
        email: '',
        password: '',
        role_id: '',
        division_id: '',
        airport_id: [],
        is_active: ''
      },
      formData: null,
      url: null,
      img_api: '',
      is_removeimage: null,
      passcfg: {
        showpass: false,
        iconpass: 'eye-slash',
        typepass: 'password'
      },
      errors: {
        nrp: '',
        name: '',
        profile_picture: '',
        address: '',
        phone: '',
        email: '',
        password: '',
        role_id: '',
        division_id: '',
        airport_id: '',
        is_active: ''
      }
    }
  },
  async created() {
    this.isLoading = true
    await this.getRole()
    await this.getDivision()
    await this.getAirport()
    if(this.$route.params.id) {
        await this.showData()
    }
    this.isLoading = false
  },
  methods: {
    ...mapActions('users', ['storeUSR', 'updateUSR', 'showUSR']),
    ...mapActions('role', ['indexRL']),
    ...mapActions('division', ['indexDVS']),
    ...mapActions('airport', ['indexAPT']),
    postUser(){
      this.isLoading = true
      this.generateFormData()
      this.storeUSR(this.formData).then((response) => {
        if(response) { this.$router.push('/users') }
        this.isLoading = false
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error.statusText })
        this.getError(error.data)
        this.isLoading = false
      })
    },
    updateUser(){
      this.isLoading = true
      this.generateFormData()
      this.updateUSR(this.formData).then((response) => {
        if(response) {
          this.$router.push('/users')
        }
        this.isLoading = false
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error.statusText })
        this.getError(error.data)
        this.isLoading = false
      })
    },
    async showData(){
      await this.showUSR(this.$route.params.id).then((response) => {
        this.form = response
        this.url = response.profile_picture
        this.img_api = response.profile_picture
        this.form.password = ''
        this.form.profile_picture = null
        this.filterAirport(response.airport_rights)
        this.generateFormData()
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error })
      })
    },
    async getRole(){
        await this.indexRL().then((response) => {
            response.forEach((item, index) => {
                this.roles[index] = { value: item.id, label: item.name }
            })
        }).catch((error) => {
            this.flashMessage.error({ title: 'Error', message: error })
        })
    },
    async getDivision(){
        await this.indexDVS().then((response) => {
            response.forEach((item, index) => {
                this.divisions[index] = { value: item.id, label: item.name }
            })
        }).catch((error) => {
            this.flashMessage.error({ title: 'Error', message: error })
        })
    },
    async getAirport(){
        await this.indexAPT().then((response) => {
            response.forEach((item, index) => {
              if(item.is_active) {
                this.airports[index] = { value: item.id, label: item.name }
              }
            })
        }).catch((error) => {
            this.flashMessage.error({ title: 'Error', message: error })
        })
    },
    changeAirport(val) {
      this.value_airports = val
      this.form.airport_id = val.map(function (obj) {
        return obj.value
      })
    },
    filterAirport(val) {
      this.value_airports = val.map(function (obj) {
        return { value: obj.id, label: obj.name }
      })
    },
    triggerImg() {
      this.$refs.inputImage.click();
    },
    onFileChanged(e) {
      if(e.target.files[0].size<=1024000) {
        this.form.profile_picture = e.target.files[0];
        this.url = URL.createObjectURL(this.form.profile_picture);
        this.errors.profile_picture = ''
      } else {
        this.errors.profile_picture = 'File max 1MB'
      }
      console.log('size', e.target.files[0].size) // 2697333 B
    },
    onFileRemoved(e) {
      if(this.img_api) {
        this.is_removeimage = 'true'
      } else {
        this.is_removeimage = null
      }
      this.form.profile_picture = null;
      this.url = null;
    },
    showPassword(){
      if(this.passcfg.showpass){
        this.passcfg = {
          showpass: false,
          iconpass: 'eye-slash',
          typepass: 'password'
        }
      } else {
        this.passcfg = {
          showpass: true,
          iconpass: 'eye',
          typepass: 'text'
        }
      }
    },
    generateFormData(){
      this.formData = new FormData()
      this.formData.append('uuid', this.form.uuid)
      this.formData.append('nrp', this.form.nrp)
      this.formData.append('name', this.form.name)
      if(this.form.profile_picture) {
        this.formData.append('profile_picture', this.form.profile_picture)
      }
      if(this.is_removeimage) {
        this.formData.append('is_removeimage', this.is_removeimage)
      }
      this.formData.append('address', this.form.address?this.form.address:'')
      this.formData.append('phone', this.form.phone?this.form.phone:'')
      this.formData.append('email', this.form.email)
      if(this.form.password) {
        this.formData.append('password', this.form.password)
      }
      this.formData.append('role_id', this.form.role_id)
      if(this.form.role_id!=1 && this.form.division_id){
        this.formData.append('division_id', this.form.division_id)
      }
      this.value_airports.forEach((item, index) => {
        this.formData.append('airport_id[]', item.value)
      })
      this.formData.append('awos_selected', this.value_airports[0].value)
      this.formData.append('is_active', this.form.is_active)
    },
    getError(data){
      if(typeof(data) == 'object' && data){ if(data.errors) { 
        this.errors.nrp = data.errors.nrp ? data.errors.nrp[0] : ''
        this.errors.name = data.errors.name ? data.errors.name[0] : ''
        this.errors.profile_picture = data.errors.profile_picture ? data.errors.profile_picture[0] : ''
        this.errors.address = data.errors.address ? data.errors.address[0] : ''
        this.errors.phone = data.errors.phone ? data.errors.phone[0] : ''
        this.errors.email = data.errors.email ? data.errors.email[0] : ''
        this.errors.password = data.errors.password ? data.errors.password[0] : ''
        this.errors.role_id = data.errors.role_id ? data.errors.role_id[0] : ''
        this.errors.division_id = data.errors.division_id ? data.errors.division_id[0] : ''
        this.errors.airport_id = data.errors.airport_id ? data.errors.airport_id[0] : ''
        this.errors.is_active = data.errors.is_active ? data.errors.is_active[0] : ''
      }}
    }
  }
}
</script>
<style scoped>
  #preview {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #preview img {
    max-width: 40%;
    max-height: 40%;
  }
</style>